import { HttpClient } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-price-sidebar',
  templateUrl: './price-sidebar.component.html',
  styleUrls: ['./price-sidebar.component.scss'],
})
export class PriceSidebarComponent implements OnInit {
  @Output() trigger = new EventEmitter<any>();

  public activeTab = 1;
  public parenTrades = [];
  public ASSET_URL = environment?.assetUrl + 'assets/';
  public BASE_URL = environment.SERVER_URL;
  isSidebarClosed = false;

toggleSidebar() {
  this.isSidebarClosed = !this.isSidebarClosed;
}
  constructor(private http: HttpClient, private utils: UtilityService) { }
  emitData = (id: number) => {
    this.activeTab = id;
    this.trigger.emit({ service: this.activeTab });
  }
  ngOnInit(): void {
    this.getParenTrades();
  }

  getParenTrades = () => {
    const SERVICES_URL = `${this.BASE_URL}parenttrades`;
    this.http
      .get(
        SERVICES_URL,
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        this.parenTrades = data.services;
      });
  }
  renderIcons = (parenTrade: any) => {
    switch (parenTrade.id) {
      case 1:
        return '../../assets/images/DryCleaning.svg';
      case 5:
        return '../../assets/images/LaundryWashIron.svg';
      case 4:
        return '../../assets/images/LaundryWashIron.svg';
      case 2:
        return '../../assets/images/PremiumLaundry.svg';
      case 3:
        return '../../assets/images/SteamPress.svg';
      case 9:
        return '../../assets/images/PremiumLaundry.svg';
        case 10:
        return '../../assets/images/SteamPress.svg';
      default:
        return '';
      
    }

  }
  

  getIcon = (parenTrade: any) => {
        return this.renderIcons(parenTrade);
  }

  scrollToBottom() {
    console.log("this is working")
    const content = document.querySelector('.sidebar-content');
    if (content) {
        content.scrollBy({
            top: 2000, // Adjust this value to control scroll distance
            behavior: 'smooth'
        });
    }
}
}