import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiService } from 'src/app/services/api.service';
import { AmountModalComponent } from '../amount-modal/amount-modal.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {
  isPending: boolean = true;
  containerHeight: string = '180px';
  isDropdownClicked: boolean = false;
  
  paymentModes: Array<{ item_id: number, item_text: string }> = [
    { item_id: 1, item_text: 'UPI/Wallet App' },
    { item_id: 2, item_text: 'Credit/Debit card' },
    { item_id: 3, item_text: 'Bank Transfer' },
    { item_id: 4, item_text: 'Cash' }
  ];
  
  selectedModes: Array<{ item_id: number, item_text: string }> = [];
  
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 4,
    allowSearchFilter: false
  };

  bookingId: number;
  finalAmount: number;

  constructor(
    public dialogRef: MatDialogRef<PaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {
    this.bookingId = data.bookingId;
    this.finalAmount = data.finalAmount;
  }

  ngOnInit() {
    this.updateContainerHeight();
  }

  // Method to handle dropdown click
  onDropdownClick(): void {
    this.isDropdownClicked = true;
    this.updateContainerHeight();
  }

  onDropDownClose(): void {
    this.isDropdownClicked = false;
    this.updateContainerHeight();
  }

  updateContainerHeight(): void {
    if (this.isPending) {
      // Pending state always stays at 180px
      this.containerHeight = '180px';
    } else {
      // Payment Done state
      if (this.isDropdownClicked) {
        // Dropdown open
        this.containerHeight = '430px';
      } else {
        // Dropdown closed
        this.containerHeight = '280px';
      }
    }
    console.log('Container height updated to:', this.containerHeight);
  }

  onPendingChange(): void {
    this.selectedModes = [];
    this.isDropdownClicked = false;
    this.updateContainerHeight();
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  
  updateOrderStatus() {
    return this.apiService.updateOrderStatus(this.bookingId, 7);
  }
  
  onNext(): void {
    if (this.isPending) {
      this.updateOrderStatus().subscribe(
        (statusResponse: any) => {
          this.dialogRef.close({ statusResponse });
          this.openSuccessModal(statusResponse);
        },
        (error) => {
          console.error('Error updating order status', error);
        }
      );
    } else if (this.selectedModes.length > 0) {
      const amountDialogRef = this.dialog.open(AmountModalComponent, {
        width: '500px',
        data: {
          modes: this.selectedModes,
          bookingId: this.bookingId,
          finalAmount: this.finalAmount
        }
      });
  
      amountDialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close(result);
      });
    }
  }

  private openSuccessModal(statusResponse: any): void {
    const successDialogRef = this.dialog.open(SuccessModalComponent, {
      width: '500px',
      data: {
        paymentResponse: null,
        statusResponse: statusResponse,
        message: 'Order marked as pending and completed successfully!'
      }
    });

    successDialogRef.afterClosed().subscribe(result => {
      this.dialogRef.close({ success: true, statusResponse: statusResponse });
    });
  }
}