<div class="container">
  <div class="dashboard-heading"><app-customer-directory-search
  (searchResults)="handleSearchResults($event)"
  (totalPagesUpdate)="handleTotalPagesUpdate($event)"
></app-customer-directory-search>

<div style="margin-left: 30px;" >

<app-customer-directory-date
(dateRangeChange)="handleDateRangeChange($event)"
></app-customer-directory-date>


</div> <button
mat-ripple
class="add-order-btn"
(click)="gotoUrl('order-flow/step2')"
>
Add New Customer <span class="vertical-line"></span>&nbsp;+
</button>

</div>
  

</div>
<section class="customer-directory-section">
<div class="container">
<div class="row">
<div class="col-md-12">
<div class="table-section mt-20">
  <div *ngIf="users === null" class="loader-container">
    <app-loader></app-loader>
  </div>
  <div class="table-container">
    <table class="order-table" *ngIf="users !== null">
        <thead>
          <tr>
            <th >
              Customer name
              
            </th>
            <th >
              Contact details
            
            </th>
            <th (click)="sortAscending('total_bookings')">
              Total bookings
              <span class="sort-icon">{{ getSortIcon('total_bookings') }}</span>
            </th>
            <th (click)="sortAscending('total_value_ordered')">
              Total value
              <span class="sort-icon">{{ getSortIcon('total_value_ordered') }}</span>
            </th>
            <th (click)="sortAscending('created_at')">
              Customer since
              <span class="sort-icon">{{ getSortIcon('created_at') }}</span>
            </th>
            <th (click)="sortAscending('latest_booking_date')">
              Last Order
              <span class="sort-icon">{{ getSortIcon('latest_booking_date') }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let orderObj of users; let i = index">
            <td>
              <div class="order-type" (click)="gotoUrl('dashboard/user/' + orderObj.id)">
              {{ orderObj.name ? orderObj.name : "-" }}
              </div>
              
            </td>
            
            <td><div class="order-type">{{orderObj.mobile}}</div>{{ orderObj.email ? orderObj.email : "" }}</td>
            <td>{{ orderObj.total_bookings }}</td>
            <td class="address-col"> ₹{{
              orderObj.total_value_ordered
                ? formatNumber(orderObj.total_value_ordered)
                : 0
            }}</td>
            <td>
              <span class="status-badge" >{{ getFormattedDate(orderObj.created_at) }}</span>
            </td>
            <td class="invoice-col">
                <div class="invoice-code">
                  {{
                    getFormattedDate(orderObj.latest_booking_date)
                      ? getFormattedDate(orderObj.latest_booking_date)
                      : "-"
                  }}
                </div>
                
              </td>
          </tr>
        </tbody>
      </table>
  </div>

</div>
</div>
</div>
<br />
<div style="display: flex; justify-content: flex-end">
<p *ngIf="totalPages !== 1">
Showing results {{ (currentPage - 1) * 30 }} to
{{ currentPage * 30 }} Out of {{ totalPages * 30 }}
</p>
<p *ngIf="totalPages === 1">Showing results 0 To {{ users.length }}</p>
</div>
<br />
<div class="pagination-container">
<app-pagination
(page)="updatePageNumber($event)"
[totalPages]="totalPages"
></app-pagination>
</div>
<br />
</div>
</section>