<section class="coupon-section">
  <!-- Search and Add Button Row -->
  <div class="search-container">
    <input
      class="search-input"
      (input)="searchCoupons($event)"
      placeholder="Search coupon with coupon code"
    />
    <button class="btn primary" (click)="gotoUrl('add-coupons-step-1')">
      + New Coupon
    </button>
  </div>

  <!-- Coupon Table -->
  <div class="table-wrapper">
    <app-loader *ngIf="isLoading"></app-loader>

    <table *ngIf="!isLoading && data.length > 0" class="coupon-table">
      <thead>
        <tr>
          <th>Sr. #</th>
          <th>
            Creation Date
            <span class="sort-icon" (click)="sortAscending('created_at')">
              {{getSortIcon("created_at")}}
            </span>
          </th>
          <th>
            Code
            <span class="sort-icon" (click)="sortAscending('discount_code')">
              {{getSortIcon("discount_code")}}
            </span>
          </th>
          <th>Terms</th>
          <th>Valid On</th>
          <th>Order</th>
          <th>
            Start Date
            <span class="sort-icon" (click)="sortAscending('start_date')">
              {{getSortIcon("start_date")}}
            </span>
          </th>
          <th>
            End Date
            <span class="sort-icon" (click)="sortAscending('end_date')">
              {{getSortIcon("end_date")}}
            </span>
          </th>
          <th>Status</th>
          <th>Coupon Type</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let coupon of data; let i = index">
          <td>{{i + 1}}</td>
          <td>{{coupon.created_at}}</td>
          <td class="code">{{coupon.discount_code}}</td>
          <td class="terms">
            <div>Minimum Order: {{coupon.minimum_order}}</div>
            <div>Maximum Discount: {{coupon.maximum_discount}}</div>
          </td>
          <td>{{coupon.name}}</td>
          <td>
            <span class="order-type">
              {{coupon.order_no === 2 ? "Any Order" : "First Order"}}
            </span>
          </td>
          <td>{{coupon.start_date | date}}</td>
          <td>{{coupon.end_date | date}}</td>
          <td>
            <span [ngClass]="renderStatus(coupon).toLowerCase()">
              {{renderStatus(coupon)}}
            </span>
          </td>
          <td>
            <span class="order-type">
              {{coupon.channel_type === 1 ? "Online" : "Offline"}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="!isLoading && data.length === 0" class="no-data">
      No coupons found.
    </div>
  </div>
</section>