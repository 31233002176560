import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { UtilityService } from 'src/app/service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-directory-search',
  templateUrl: './customer-directory-search.component.html',
  styleUrls: ['./customer-directory-search.component.scss']
})
export class CustomerDirectorySearchComponent implements OnInit {
  ngOnInit(): void {
  
  }
  @Output() searchResults = new EventEmitter<any>();
  @Output() totalPagesUpdate = new EventEmitter<number>();
  
  private currentPage = 1;
  private sort = '';
  private search = '';
  
  constructor(
    private http: HttpClient,
    private utils: UtilityService
  ) {}
  
  
  handleSearch(event: any) {
    this.currentPage = 1;
    if (event.target.value.length === 0) {
      this.search = '';
      this.getMethod();
      return;
    }
    this.search = event.target.value;
    this.getusersMobile();
  }
  
  private getusersMobile() {
    this.http
      .get(
        `${environment.SERVER_URL}webuser/searchUserMobile?franchise=${environment.FRANCHISE}&mobile=${this.search}&sort=${this.sort}&page=${this.currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe(
        (data: any) => {
          this.searchResults.emit(data.data);
          this.totalPagesUpdate.emit(data.totalPages);
        },
        (error: HttpErrorResponse) => {
          console.error('Error fetching users:', error.message);
        }
      );
  }
  
  private getMethod() {
    this.http
      .get(
        `${environment.SERVER_URL}webuser/getusers?franchise=${environment.FRANCHISE}&page=${this.currentPage}&sort=${this.sort}`,
        {
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        this.searchResults.emit(data.data);
        this.totalPagesUpdate.emit(data.totalPages);
      });
  }
  
  setSort(sortValue: string) {
    this.sort = sortValue;
  }
  
  setPage(page: number) {
    this.currentPage = page;
    if (this.search) {
      this.getusersMobile();
    } else {
      this.getMethod();
    }
  }  
}