<div class="container">
    <div class="section">
      <div style="display: flex; justify-content: flex-end">
        <mat-icon class="closeBtn" (click)="closeModal()">close</mat-icon>
      </div>
      <div *ngIf="!typeOfMessage(message)">
        <app-notifications
          [message]="message"
          [type]="messageType"
        ></app-notifications>
      </div> 
     
       <div style="width: 15%;
    margin-left: 40%; margin-bottom: 20px;">   <img [src]="cancel" alt="dropdown-icon" /> </div>
     
      <div *ngIf="typeOfMessage(message)">
        <div *ngFor="let e of message">
          <app-notifications [message]="e" [type]="1"></app-notifications>
        </div>
      </div>
      <h6>{{ messageToDisplay }}</h6>
    </div>
    <br />
    <br />
    <div
      class="bodySection"
      style="display: flex; align-items: center; justify-content: space-around"
    >
      <div class="item">
        <div class="btn-wrapper">
          <button (click)="closeModal()">back</button>
        </div>
      </div>
  
      <div class="btn-wrapper">
        <button class="btn cancel" (click)="handlePost()">Cancel Order</button>
      </div>
    </div>
    <br />
  </div>
  