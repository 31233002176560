import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-coupons-table',
  templateUrl: './coupons-table.component.html',
  styleUrls: ['./coupons-table.component.scss'],
})
export class CouponsTableComponent implements OnInit {
  public data: any[] = [];
  public isLoading: boolean = true;
  public isSearchMode: boolean = false;
  public searchQuery: string = '';
  private searchSubject = new Subject<string>();
  sortColumn: string = '';
  sortDirection: string = 'asc';
  orders: any;
  coupon: any;

  constructor(
    private apiService: ApiService,
    private router: Router
  ) {
    // Initialize search debounce
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(query => {
      this.performSearch(query);
    });
  }

  ngOnInit(): void {
    console.log('Component initialized');
    this.getCoupons();
  }

  gotoUrl = (url: string) => {
    this.router.navigateByUrl(url);
  }

  renderStatus = (c: any) => {
    switch (true) {
      case c.status === 0:
        return 'Cancelled';
      case new Date(c.end_date).getTime() < new Date(Date.now()).getTime():
        return 'Expired';
      default:
        return 'Valid';
    }
  }

  getSortIcon(column: string): string {
    if (this.sortColumn !== column) {
      return '↕';
    }
    return this.sortDirection === 'asc' ? '↑' : '↓';
  }

  sortAscending(column: string) {
    this.sortDirection = this.sortColumn === column && this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortColumn = column;

    if (this.data) {
      this.data.sort((a, b) => {
        if (a[column] < b[column]) {
          return this.sortDirection === 'asc' ? -1 : 1;
        }
        if (a[column] > b[column]) {
          return this.sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
  }
  handleDataFetch = (response: any, isSearch: boolean = false) => {
    console.log(`${isSearch ? 'Search' : 'GetCoupons'} API response:`, response);
    
    // Handle both response formats:
    // 1. Array format (from search): [{...}, {...}]
    // 2. Object format (from getCoupons): { data: [{...}, {...}] }
    if (Array.isArray(response)) {
      console.log('Handling array response format');
      this.data = [...response];
    } else if (response?.data) {
      console.log('Handling object response format');
      this.data = [...response.data];
    } else {
      console.log('No valid data in response');
      this.data = [];
    }
    
    console.log('Final updated data:', this.data);
    this.isLoading = false;
  }

  // ... rest of the component code remains the same ...

  searchCoupons = (event: any) => {
    const query = event.target.value.trim();
    console.log('Search input event:', query);
    this.searchQuery = query;
    this.searchSubject.next(query);
  }

  private performSearch = (query: string) => {
    console.log('Performing search with query:', query);
    
    if (query === '') {
      console.log('Empty query, reverting to normal view');
      this.isSearchMode = false;
      this.getCoupons();
      return;
    }

    this.isLoading = true;
    this.isSearchMode = true;

    this.apiService.searchCoupons(query).subscribe({
      next: (response: any) => {
        console.log('Search API response received:', response);
        this.handleDataFetch(response, true);
      },
      error: (error) => {
        console.error('Error searching coupons:', error);
        this.isLoading = false;
        this.data = [];
      }
    });
  }

  getCoupons = () => {
    console.log('Fetching all coupons');
    this.isLoading = true;
    
    this.apiService.getCoupons().subscribe({
      next: (response: any) => {
        console.log('GetCoupons API response received:', response);
        this.handleDataFetch(response);
      },
      error: (error) => {
        console.error('Error fetching coupons:', error);
        this.isLoading = false;
        this.data = [];
      }
    });
  }
}