import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  @Input() type: string = 'NEW';
  @Output() dateRangeChange = new EventEmitter<{fromDate: string, toDate: string, selectedOptions: string[], selectedApplyOptions: string[]}>();

  isOpen = false;
  selectedOptions: string[] = ['Any Date'];
  selectedApplyOptions: string[] = [];
  dateOptions = ['Any Date', 'Today', 'Last 7 Days', 'Last 30 Days', 'Last Year', 'Custom'];
  applyOnOptions = ['Order Date', 'Pickup Date', 'Delivery Date', 'Invoice Date'];
  fromDate: string = '';
  toDate: string = '';
  dropdownIcon = `${environment.assetUrl}assets/images/icons/dropdowns.svg`;

  constructor() { }

  ngOnInit(): void {
    console.log('DateRangeComponent initialized');
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    console.log('Dropdown toggled. isOpen:', this.isOpen);
  }

  closeDropdown() {
    this.isOpen = false;
  }

  onOptionChange(option: string) {
    console.log('Option changed:', option);
    // Clear previous selections and add new option
    this.selectedOptions = [option];

    // Reset dates when changing option
    if (option !== 'Custom') {
      this.fromDate = '';
      this.toDate = '';
    }

    this.emitDateRange();

    // If Custom is selected, keep dropdown open
    this.isOpen = option === 'Custom';
  }

  onApplyOptionChange(option: string) {
    console.log('Apply option changed:', option);
    const index = this.selectedApplyOptions.indexOf(option);
    if (index > -1) {
      this.selectedApplyOptions.splice(index, 1);
    } else {
      this.selectedApplyOptions.push(option);
    }
    console.log('Current selected apply options:', this.selectedApplyOptions);
    this.emitDateRange();
  }

  getSelectedOptionsText(): string {
    if (this.selectedOptions[0] === 'Custom' && this.fromDate && this.toDate) {
      return `${this.fromDate} - ${this.toDate}`;
    }
    return this.selectedOptions[0];
  }

  saveCustomDate() {
    console.log('Saving custom date range');
    if (this.fromDate && this.toDate) {
      // Convert dates from yyyy-mm-dd to dd/mm/yyyy
      const formattedFromDate = this.formatDate(this.fromDate);
      const formattedToDate = this.formatDate(this.toDate);
      console.log('Custom date range:', formattedFromDate, 'to', formattedToDate);
      this.fromDate = formattedFromDate;
      this.toDate = formattedToDate;
      this.selectedOptions = ['Custom'];
      this.emitDateRange();
      this.isOpen = false;
    } else {
      // Optional: Add validation to ensure both dates are selected
      console.log('Please select both From and To dates');
    }
  }

  cancelCustomDate() {
    console.log('Cancelling custom date range');
    this.fromDate = '';
    this.toDate = '';
    this.selectedOptions = ['Any Date'];
    this.emitDateRange();
    this.isOpen = false;
  }

  private emitDateRange() {
    console.log('Emitting date range change');
    this.dateRangeChange.emit({
      fromDate: this.fromDate,
      toDate: this.toDate,
      selectedOptions: this.selectedOptions,
      selectedApplyOptions: this.selectedApplyOptions
    });
  }

  private formatDate(date: string): string {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year}`;  // Changed from '/' to '-'
  }
}