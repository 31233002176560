<div class="sidebar" [ngClass]="{ 'sidebar-closed': isSidebarClosed }">
  <div class="sidebar-heading" (click)="toggleSidebar()">
      <img src="../../assets/images/sidebar.svg" alt="Sidebar Logo" />
  </div>
  <br />
  
  <!-- Scrollable content container -->
  <div class="sidebar-content">
      <div
          class="sidebar-over"
          (click)="emitData(parenTrade?.id)"
          [ngClass]="{ active: activeTab === parenTrade?.id }"
          *ngFor="let parenTrade of parenTrades"
      >
          <img [src]="getIcon(parenTrade)" />
          <div class="trade-name" *ngIf="!isSidebarClosed">{{ parenTrade?.name }}</div>
      </div>
      
      <!-- Scroll button -->
      <!-- <button class="scroll-button" (click)="scrollToBottom()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M6 9l6 6 6-6"/>
          </svg>
      </button> -->
  </div>
  <div class="sidebar-bottom" (click)="scrollToBottom()">
    <img src="../../assets/images/down.svg" alt="Sidebar Logo" />
</div>
</div>