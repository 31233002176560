  <div class="search-container">
    <input
      class="search-input"
      (keyup)="handleSearch($event)"
      (input)="handleInputChange($event)"
      placeholder="Search by Mobile No., Name, Booking Code..."
    />
  
  </div>

