import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/service/utility.service';
import { AppService } from 'src/app/service/app.service';
import { CustomerDirectorySearchComponent } from '../customer-directory-search/customer-directory-search.component';

@Component({
  selector: 'app-customer-directory',
  templateUrl: './customer-directory.component.html',
  styleUrls: ['./customer-directory.component.scss'],
})
export class CustomerDirectoryComponent implements OnInit {
  @ViewChild(CustomerDirectorySearchComponent)
  searchComponent: CustomerDirectorySearchComponent;

  public users: any = null;
  public searchedUsers: any;
  public dropIcon = `${environment.assetUrl}assets/images/icons/dropdown.svg`;
  public pickupIcon = `${environment.assetUrl}assets/images/icons/pickup.svg`;
  public totalUsers: number = 0;
  public currentPage: number = 1;
  public totalPages: number = 0;
  public sortingItems: any = [];
  public sort: string = '';
  sortColumn: string = '';
  sortDirection: string = 'asc';
  dateFilter: any = {
    fromDate: '',
    toDate: '',
    selectedOptions: [],
    selectedApplyOptions: []
  };

  constructor(
    private http: HttpClient,
    public apiRep: AppService,
    private router: Router,
    private utils: UtilityService
  ) {
    this.sortingItems = [
      { name: 'Ascending total value', value: 'TVACS' },
      { name: 'Descending total value', value: 'TVDES' },
      { name: 'Ascending total bookings', value: 'TBACS' },
      { name: 'Descending total bookings', value: 'TBDES' },
      { name: 'Ascending last order', value: 'LOACS' },
      { name: 'Descending last order', value: 'LODES' },
    ];
  }
  

  ngOnInit(): void {
    if (this.apiRep.currentSort) {
      this.sort = this.apiRep.currentSort;
      this.searchComponent?.setSort(this.sort);
    }
    this.getMethod();
  }

  
    // Method to handle search results from search component
    handleSearchResults(results: any) {
      this.users = results;
      this.applyDateFilter();
    }
  
    // Method to handle total pages update from search component
    handleTotalPagesUpdate(totalPages: number) {
      this.totalPages = totalPages;
    }
  
    // Updated pagination method
    updatePageNumber(ev: any) {
      this.currentPage = ev.page;
      this.searchComponent?.setPage(this.currentPage);
    }

    // Updated sort method
    sortData(val: any) {
      this.users = null;
      this.sort = val;
      this.apiRep.currentSort = val;
      this.currentPage = 1;
      this.searchComponent?.setSort(this.sort);
  }

  sortAscending(column: string) {
    // If clicking the same column, toggle direction
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column, default to ascending
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    
    // Make a copy of users array to avoid mutation issues
    if (this.users && Array.isArray(this.users)) {
      const sortedUsers = [...this.users];
      
      sortedUsers.sort((a, b) => {
        let valueA: any;
        let valueB: any;

        // Handle different column types
        switch(column) {
          case 'mobile':
          case 'name':
          case 'email':
            valueA = (a[column] || '').toLowerCase();
            valueB = (b[column] || '').toLowerCase();
            break;
          
          case 'total_bookings':
          case 'total_value_ordered':
            valueA = Number(a[column] || 0);
            valueB = Number(b[column] || 0);
            break;
          
          case 'created_at':
          case 'latest_booking_date':
            valueA = new Date(a[column] || '1970-01-01').getTime();
            valueB = new Date(b[column] || '1970-01-01').getTime();
            break;
          
          default:
            valueA = a[column];
            valueB = b[column];
        }

        // Perform the comparison
        if (valueA < valueB) {
          return this.sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
          return this.sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });

      this.users = sortedUsers;
    }
  }

  getSortIcon(column: string): string {
    if (this.sortColumn !== column) {
      return '↕';
    }
    return this.sortDirection === 'asc' ? '↑' : '↓';
  }

    getFormattedDate(date: string) {
      if (!date) {
        return '';
      }
      const newDate = new Date(date).toDateString();
      return newDate;
    }
  
    formatNumber(numb: number) {
      return Math.round(numb);
    }
  
  handleDateRangeChange(dateRange: any) {
    this.dateFilter = dateRange;
    this.applyDateFilter();
  }

  applyDateFilter() {
    let filteredUsers = [...this.users];
    
    if (this.dateFilter.selectedOptions.length > 0) {
      this.dateFilter.selectedApplyOptions.forEach((applyOption: string) => {
        const dateField = applyOption === 'Customer Since' ? 'created_at' : 'latest_booking_date';
        
        filteredUsers = filteredUsers.filter(user => {
          const userDate = new Date(user[dateField]);
          const today = new Date();
          
          return this.dateFilter.selectedOptions.some((option: string) => {
            switch(option) {
              case 'Today':
                return this.isSameDay(userDate, today);
              case 'Last 7 Days':
                const sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));
                return userDate >= sevenDaysAgo;
              case 'Last 30 Days':
                const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
                return userDate >= thirtyDaysAgo;
              case 'Last Year':
                const lastYear = new Date(today.setFullYear(today.getFullYear() - 1));
                return userDate >= lastYear;
              case 'Custom':
                if (this.dateFilter.fromDate && this.dateFilter.toDate) {
                  const fromDate = this.parseDate(this.dateFilter.fromDate);
                  const toDate = this.parseDate(this.dateFilter.toDate);
                  return userDate >= fromDate && userDate <= toDate;
                }
                return true;
              default:
                return true;
            }
          });
        });
      });
    }
    
    this.users = filteredUsers;
    this.totalPages = Math.ceil(filteredUsers.length / 30);
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getFullYear() === date2.getFullYear();
  }

  private parseDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('/');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }


  public getMethod() {
    this.http
      .get(
        `${environment.SERVER_URL}webuser/getusers?franchise=${environment.FRANCHISE}&page=${this.currentPage}&sort=${this.sort}`,
        {
          headers: {
            Authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        this.users = data.data;
        this.totalPages = data.totalPages;
        this.applyDateFilter(); // Apply date filter after getting initial data
      });
  }

  public goToUrl(url: string, user: string) {
    this.router.navigateByUrl(url);
  }
  public gotoUrl = (url: string) => {
  this.router.navigateByUrl(url);
}
}