import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-directory-date',
  templateUrl: './customer-directory-date.component.html',
  styleUrls: ['./customer-directory-date.component.scss']
})
export class CustomerDirectoryDateComponent implements OnInit {
  @Input() type: string = 'NEW';
  @Output() dateRangeChange = new EventEmitter<{fromDate: string, toDate: string, selectedOptions: string[], selectedApplyOptions: string[]}>();

  isOpen = false;
  selectedOptions: string[] = [];
  selectedApplyOptions: string[] = [];
  dateOptions = ['Today', 'Last 7 Days', 'Last 30 Days', 'Last Year', 'Custom'];
  applyOnOptions = ['Customer Since', 'Last Order'];
  fromDate: string = '';
  toDate: string = '';
  dropdownIcon = `${environment.assetUrl}assets/images/icons/dropdown_green.svg`;

  constructor() { }

  ngOnInit(): void {
    console.log('DateRangeComponent initialized');
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    console.log('Dropdown toggled. isOpen:', this.isOpen);
  }

  onOptionChange(option: string) {
    console.log('Option changed:', option);
    const index = this.selectedOptions.indexOf(option);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
      console.log('Option unchecked:', option);
      if (option === 'Custom') {
        this.fromDate = '';
        this.toDate = '';
        console.log('Custom date range cleared');
      }
    } else {
      this.selectedOptions.push(option);
      console.log('Option checked:', option);
    }
    console.log('Current selected options:', this.selectedOptions);
    this.emitDateRange();
  }

  onApplyOptionChange(option: string) {
    console.log('Apply option changed:', option);
    const index = this.selectedApplyOptions.indexOf(option);
    if (index > -1) {
      this.selectedApplyOptions.splice(index, 1);
    } else {
      this.selectedApplyOptions.push(option);
    }
    console.log('Current selected apply options:', this.selectedApplyOptions);
    this.emitDateRange();
  }

  getSelectedOptionsText(): string {
    console.log('Getting selected options text');
    if (this.selectedOptions.length === 0) {
      return 'Any Date';
    } else if (this.selectedOptions.length === 1) {
      return this.selectedOptions[0];
    } else {
      return `${this.selectedOptions.length} options selected`;
    }
  }

  saveCustomDate() {
    console.log('Saving custom date range');
    if (this.fromDate || this.toDate) {
      if (!this.selectedOptions.includes('Custom')) {
        this.selectedOptions.push('Custom');
      }
      // Convert dates from yyyy-mm-dd to dd/mm/yyyy
      const formattedFromDate = this.formatDate(this.fromDate);
      const formattedToDate = this.formatDate(this.toDate);
      console.log('Custom date range:', formattedFromDate, 'to', formattedToDate);
      this.fromDate = formattedFromDate;
      this.toDate = formattedToDate;
    }
    this.emitDateRange();
    this.isOpen = false;
  }

  cancelCustomDate() {
    console.log('Cancelling custom date range');
    this.fromDate = '';
    this.toDate = '';
    const customIndex = this.selectedOptions.indexOf('Custom');
    if (customIndex > -1) {
      this.selectedOptions.splice(customIndex, 1);
    }
    this.emitDateRange();
    this.isOpen = false;
  }

  private emitDateRange() {
    console.log('Emitting date range change');
    this.dateRangeChange.emit({
      fromDate: this.fromDate,
      toDate: this.toDate,
      selectedOptions: this.selectedOptions,
      selectedApplyOptions: this.selectedApplyOptions
    });
  }

  private formatDate(date: string): string {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

}