<div #modalContainer class="modal-container" [ngStyle]="{'height': containerHeight}">
  <h1>Confirm Payment</h1>
  
  <div style="display: flex; justify-content: space-between; padding: 20px 30px 10px;">
    <div class="radio-group">
      <label>
        <input
          type="radio"
          class="radio-button"
          name="payment-status"
          [(ngModel)]="isPending"
          [value]="true"
          (change)="onPendingChange()">
        Payment Pending
      </label>
    </div>
    
    <div class="radio-group">
      <label>
        <input
          type="radio"
          class="radio-button"
          name="payment-status"
          [(ngModel)]="isPending"
          [value]="false"
          (change)="onPendingChange()">
        Payment Done
      </label>
    </div>
  </div>
  
  <div class="payment-mode-dropdown" *ngIf="!isPending">
    <label>Payment Mode</label>
    <ng-multiselect-dropdown 
      [placeholder]="'Choose Payment Mode'"
      [settings]="dropdownSettings"
      [data]="paymentModes"
      [(ngModel)]="selectedModes"
      [disabled]="isPending"
      (onDropDownClose)="onDropDownClose()"
      (click)="onDropdownClick()">
    </ng-multiselect-dropdown>
  </div>
  
  <div class="button-container">
    <button class="btn secondary" (click)="onCancel()">Cancel</button>
    <button 
      class="btn primary" 
      (click)="onNext()" 
      [disabled]="selectedModes.length === 0 && !isPending">
      {{ isPending ? 'Confirm' : 'Next' }}
    </button>
  </div>
</div>